<template>
  <div class="error-page">
    <div class="content">
      <img src="../assets/img/404.png" alt="">
      <h1>Oh no! Page not found.</h1>
      <v-btn to="/" class="mainButton rounded-pill">
        Go home
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorPage'
}
</script>

<style lang="scss" scoped>
  .error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .content {
      color: #fff;
      text-align: center;
      img {
        width: 250px;
        margin-bottom: 20px;
      }
      .v-btn {
        margin-top: 50px;
      }
    }
  }
</style>
